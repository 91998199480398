import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Header from 'components/ui/Header';
import { AnalyticsAwareCheckbox } from 'components/ui/inputs/Checkbox';

import commonPropTypes from 'utils/commonPropTypes';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const Container = styled.label`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 8px;
  justify-content: space-between;
  width: 100%;

  background: ${({ checked }) =>
    checked ? svars.accentColorLightest : svars.colorLighterGrey};
  transition: ${svars.transitionBase};

  .container-header {
    margin: -1rem;
    padding: 1rem;
    transition: ${svars.transitionBase};
  }
`;

const WithChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ checked }) =>
    checked ? svars.colorWhite : svars.colorLighterGrey};
  transition: ${svars.transitionBase};

  & .container {
    padding: 0 ${svars.spaceMedium};
    &:hover {
      background: ${({ checked }) =>
        checked ? svars.colorWhite : svars.colorLightGrey};
    }
  }
`;

function TogglableSection({
  title,
  subtitle,
  checked,
  onChange,
  style,
  gaCategory,
  gaAction,
  gaLabel,
  children,
  togglableSectionTestId,
}) {
  return (
    <WithChildrenContainer checked={checked}>
      <Container style={style} checked={checked} className="container">
        <Header className="container-header">
          <span
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              marginBottom: svars.spaceSmaller,
            }}
          >
            <Trans render={capitalizedTranslation} id={title} />
            <AnalyticsAwareCheckbox
              gaCategory={gaCategory}
              gaAction={gaAction}
              gaLabel={gaLabel}
              checked={checked}
              onChange={onChange}
              toggle
              data-testid={togglableSectionTestId}
              style={{
                minWidth: '4rem',
                pointerEvents: 'none',
                marginLeft: svars.spaceNormal,
              }}
            />
          </span>
          <Header.Subheader>{subtitle}</Header.Subheader>
        </Header>
      </Container>
      <div style={{ margin: `0 ${svars.spaceMedium}` }}>
        {checked ? children : null}
      </div>
    </WithChildrenContainer>
  );
}

TogglableSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  gaCategory: PropTypes.string.isRequired,
  gaAction: PropTypes.string,
  gaLabel: PropTypes.string,
  style: commonPropTypes.style,
  togglableSectionTestId: PropTypes.string,
  children: PropTypes.node,
};

TogglableSection.defaultProps = {
  gaAction: null,
  gaLabel: null,
  style: {},
  togglableSectionTestId: undefined,
  children: null,
};

export default TogglableSection;
